import { useMiddleware } from "@/store/middleware";
import { useSession } from "@/store/session";

export default defineNuxtRouteMiddleware(async (to, from) => {
  const auth = useAuth();
  const config = useRuntimeConfig();
  const middleware = useMiddleware();
  const newSession = useSession();
  const defaultRoute = `/sessions/${to.params.id}/${auth.model_type}/${to.params.user_id}`;

  //Check if route is public
  if (to.meta.noAuth) return;

  if (auth.subdomain !== "app") {
    try {
      //  Check if subdomain exists
      await middleware.subdomain(auth.subdomain);
    } catch (e) {
      await auth.logoutNoRoute();
      // Domain does not exist so redirect to primary login
      return navigateTo(`${config.public.primaryURL}/login`, {
        external: true,
      });
    }
  }

  //  Check if user is logged in if not refetch user cookie
  if (!auth.isSuccess) {
    try {
      await auth.refetch();
    } catch (error) {
      await auth.logout();
      //  if user is not logged or error redirect to participent login
      return navigateTo(defaultRoute);
    }
  }

  if (auth.isSuccess) {
    const session = ref(null);
    try {
      session.value = await middleware.session(to.params.id);
      newSession.session = session.value;
    } catch (e) {
      console.log(e);
    }
    // If session complete dont allow access beyond login page and user is not client
    if (session.value?.session_status === "COMPLETED") {
      return navigateTo("/hub/sessions");
    }
    // Allow route access
    return;
  }

  //  if user is not logged in redirect to login
  return navigateTo("/login");
});
